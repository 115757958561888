import React from 'react';
import { 
    Box,
    Button,
    Stack,
    useDisclosure,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

const MobileMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    window.open("https://airtable.com/shrE48BMLWU3voxtB", "_new");
    onClose();
  }

  return (
    <>
      <Box as="button" onClick={onOpen}>
        <HamburgerIcon w={6} h={6} color="white" onClick={onOpen} />
      </Box>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay />
        <DrawerContent bg="gray.800">
        <DrawerCloseButton style={{color: 'white' }} _focus={{ outline: 'none' }} _hover={{ filter: 'brightness(65%)' }} />
          <DrawerBody>
            <Stack spacing="0.7rem" direction="column" mt="5">
              <div />
              <Button
                className="header-app-button"
                px="var(--button-px)"
                py="var(--button-py)"
                borderRadius="5px"
                fontSize="var(--p-font-size)"
                textColor="var(--text-primary-white)"
                background="var(--components-primary-blue)"
                _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                width="135px"
                onClick={handleClick}
              >
                Contact Team
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileMenu;
