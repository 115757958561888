import React from 'react';
import { Box, Button, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  width: 45%;

  @media only screen and (max-width: 1318px) {
    width: 40%;
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`;

const HeadingTitle = styled.h1`
  display: inline-block;
  text-align: left;
  overflow-wrap: anywhere;
  background: linear-gradient(90deg, #2288FF, #87BEFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: calc(2.2 * var(--h1-font-size));
  line-height: var(--header-line-height);
  font-weight: var(--header-font-weight);
  margin-bottom: var(--grid-item-bm);

  @media only screen and (max-width: 1318px) {
    font-size: calc(2 * var(--h1-font-size));
  }

  @media only screen and (max-width: 1277px) {
    font-size: calc(1.8 * var(--h1-font-size));
  }

  @media only screen and (max-width: 990px) {
    font-size: calc(1.5 * var(--h1-font-size));
  }

  @media only screen and (max-width: 960px) {
    width: 65%;
  }

  @media only screen and (max-width: 711px) {
    width: 100%;
  }
`;

const SubHeading = styled.p`
  font-size: var(--p-font-size);
  font-weight: var(--header-font-weight);
  margin-bottom: var(--grid-item-bm);
  color: var(--text-secondary-gray);
  overflow-wrap: break-word;
  width: 70%;
`;

const Info = () => {

  return (
    <Container zIndex="999">
      <HeadingTitle>Security platform for digital assets</HeadingTitle>
      <SubHeading>Realistic market simulations in real time with real data for the first time.</SubHeading>
      <Button
        className="header-app-button"
        as={Link}
        px="var(--button-px)"
        py="var(--button-py)"
        borderRadius="5px"
        fontSize="var(--p2-font-size)"
        textColor="var(--text-primary-white)"
        background="var(--components-primary-blue)"
        _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        width="140px"
        href="https://airtable.com/shrE48BMLWU3voxtB"
        target="_new"
      >
        Contact Team
      </Button>
    </Container>
  );
}

export default Info;
