import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const ChartBackground = styled(Box)`
  background: linear-gradient(180deg, #001964 0%, #04278E 100%);
  backdrop-filter: blur(92.635px);
  border-radius: 7.4108px;
  padding: 1.5rem;
  margin-left: auto;
  width: 45%;

  @media only screen and (max-width: 1446px) {
    width: 50%;
  }

  @media only screen and (max-width: 1277px) {
    width: 55%;
  }

  @media only screen and (max-width: 960px) {
    margin: 0;
    width: 75%;
  }

  @media only screen and (max-width: 840px) {
    margin: 0;
    width: 80%;
  }

  @media only screen and (max-width: 711px) {
    margin: 0;
    width: 100%;
  }

  @media only screen and (max-width: 499px) {
    height: 400px;
  }
`;

export const SUl = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  padding: 0px;
  margin: 0px;
  text-align: left;
`;

export const SLi = styled.li`
  display: inline-block;
  margin-right: 10px;
 font-size: 14px;
`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  vertical-align: middle;
  margin-right: 4px;
  border-radius: 50%;
  display: inline-block;
`;

export const FlexBox = styled.div`
  display: flex;

  @media only screen and (max-width: 499px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const Text = styled.p`
  margin-left: auto;
`;
