import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: var(--section-bm);
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  column-gap: 5rem;
  width: 100%;

  @media only screen and (max-width: 1120px) {
    padding: 0rem;
  }

  @media only screen and (max-width: 734px) {
    display: grid;
    row-gap: 2rem;
    padding: 0rem;
  }
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

export const Image = styled.img`
  width: 3rem;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  color: var(--text-primary-white);
  font-size: var(--h2-font-size);
  font-weight: 700;
  margin-bottom: 10px;

`;

export const Desc = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 500;
`;
