import React, { useState, useEffect } from 'react';
import { ChartBackground, SUl, SLi, Dot, FlexBox, Text } from './styles';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Label } from 'recharts';
import { xAxis, yAxis, splitMax, onLoadData } from '../../../constants/constants';
import { xTickFormat, yTickFormat, getRandomArbitrary, getDate } from '../../../constants/utils';

const RenderLegend = (props) => {
  const { payload, date } = props;
  return (
    <FlexBox>
        <SUl>
        {payload.map((elem) => (
            <SLi>
                <Dot style={{ backgroundColor: `${elem.color}`}} />
                {elem.value}
            </SLi>
            ))
        }
        </SUl>
        <Text>{date}</Text>
    </FlexBox>
  );
}

const StackedChart = () => {
  const [chartData, setChartData] = useState(onLoadData);
  const [chartDate, setChartDate] = useState(getDate(1));

  useEffect(() => {
    let dateCounter = 2;
    const createData = () => {
        const data = [];
        for (let i = 1; i < 37; i++) {
            data.push({
                price: (3000 / 33) * i,
                Compound: getRandomArbitrary(0, splitMax),
                Aave: getRandomArbitrary(0, splitMax),
                Maker: getRandomArbitrary(0, splitMax),
            });
        }
        dateCounter = dateCounter === 31 ? 1 : dateCounter;
        setChartDate(getDate(dateCounter));
        dateCounter += 1;
        setChartData(data);
    };

    const interval = setInterval(createData, 1100);
    return () => {
        clearInterval(interval);
    };
  }, []);

  return (
    <ChartBackground zIndex="999">
    <ResponsiveContainer>
      <BarChart
        width={600}
        height={385}
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" vertical={false} horizontal={false}/>
        <XAxis
          dataKey="price"
          tickLine={false}
          axisLine={false}
          tick={{ fill: '#B8C0CC', fontSize: "75%" }}
          type="number"
          tickFormatter={xTickFormat}
          ticks={xAxis}
          height={50}
        >
          <Label
            style={{
              textAnchor: "middle",
              fontSize: "75%",
              fill: "white",
              transform: "translateY(20px)",
            }}
            value={"ETH Price"}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={{ fill: '#B8C0CC', fontSize: "75%" }}
          type="number"
          tickFormatter={yTickFormat}
          ticks={yAxis}
        >
          <Label
            style={{
              textAnchor: "middle",
              fontSize: "75%",
              fill: "white",
            }}
            value={"USDC Borrow Amount"}
            angle={-90}
            dx={-40}
          />
        </YAxis>
        <Legend
          verticalAlign="top"
          iconSize={10}
          height={40}
          iconType={'circle'}
          align="left"
          wrapperStyle={{ fontSize: "14px", WebkitTextFillColor: "white", transform: "translateY(-15px)" }}
          content={<RenderLegend date={chartDate} />}
        />
        <Bar dataKey="Maker" stackId="a" fill="#F4C253" barSize={10}/>
        <Bar dataKey="Aave" stackId="a" fill="#915B98" barSize={10}/>
        <Bar dataKey="Compound" stackId="a" fill="#5FCF99" barSize={10}/>
      </BarChart>
      </ResponsiveContainer>
    </ChartBackground>
  );
}

export default StackedChart;
