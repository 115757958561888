import React from 'react';
import karakLogo from '../../assets/karak.svg';
import { HStack, Link } from '@chakra-ui/react';
import { Container, Text, Logo } from './styles';

const Partners = () => {
  return (
    <Container
      as={Link}
      href="https://karak.network"
      _focus={{ outline: 'none' }}
      _hover={{ textDecoration: 'none', opacity: '80%' }}
      isExternal
    >
      <Text>Powered by</Text>
      <HStack>
        <Logo src={karakLogo} alt="karak-logo" />
        <Text style={{ color: '#DF5E00' }}>Karak</Text>
      </HStack>
    </Container>
  );
}

export default Partners;
