import styled from 'styled-components';

export const Logo = styled.img`
  width: 1.7rem;
`;

export const LogoText = styled.h1`
  color: #A9BFE0;
  font-weight: 600;
  font-size: 1.35rem;
`;

export const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 999;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 960px) {
    font-size: var(--p-font-size);
  }
`;

export const DesktopContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    z-index: 999;
  }
`;
