import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: var(--section-bm);
  column-gap: 2rem;

  @media only screen and (max-width: 689px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 396px) {
    column-gap: 1rem;
  }
`;

export const Text = styled.h1`
  color: #ffffff;
  font-family: Spline Sans Mono;
  font-weight: 300;
  font-size: 3.5rem;

  @media only screen and (max-width: 689px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 475px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 396px) {
    font-size: 1.75rem;
  }
`;

export const Logo = styled.img`
  width: 2.5rem;

  @media only screen and (max-width: 689px) {
    width: 2rem;
  }

  @media only screen and (max-width: 475px) {
    width: 1.75rem;
  }

  @media only screen and (max-width: 396px) {
    width: 1.5rem;
  }
`;
