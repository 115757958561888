import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import logo from '../../assets/logo.svg';
import { FlexBox } from './styles';
import styled from 'styled-components';

const Container = styled(Box)`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const Logo = styled.img`
    width: 1.75rem;
    margin-right: 0.7rem;
`;

const Footer = () => {
  return (
    <FlexBox>
      <Container>
        <Logo src={logo} alt="RiskHarbor logo" />
        <Heading as="h1" size="md" textColor="white" fontWeight="600">Watchtower</Heading>
      </Container>
      <text style={{color: 'white'}}>Watchtower &copy; {new Date().getFullYear()}. All rights reserved.</text>
    </FlexBox>
  )
}

export default Footer;
