import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  column-gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;
`;

export const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p2-font-size) !important;
  z-index: 999;

  &:hover {
      text-decoration: underline;
      cursor: pointer;
  }
`;
