import React from 'react';
import Info from './Info';
import StackedChart from './Chart/Chart';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  margin-bottom: var(--section-bm);

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    row-gap: var(--section-bm);
  }
`;

const Intro = () => {
  return (
    <FlexBox>
      <Info />
      <StackedChart />
     </FlexBox>
  );
}

export default Intro;
