import React from 'react';
import risk from '../../assets/risk.png';
import sim from '../../assets/sim.png';
import lock from '../../assets/lock.png';
import { Container, FlexBox, ItemBox, Image, Title, Desc } from './styles';

const data = [
  {
    title: "Risk management",
    desc: "Invest responsibly in a risk aware manner.",
    src: risk,
    alt: "risk-logo"
  },
  {
    title: "Realistic simulations",
    desc: "Model real-time scenarios driven by on-chain data.",
    src: sim,
    alt: "sim-logo"
  },
  {
    title: "Institutional grade",
    desc: "Custom-built for economic, asset, and blockchain risk.",
    src: lock,
    alt: "lock-logo"
  }
]

const Features = () => {
  return (
    <Container>
      <FlexBox>
        {data.map((item) =>
          <ItemBox>
            <Image src={item.src} alt={item.alt} />
            <Title>{item.title}</Title>
            <Desc>{item.desc}</Desc>
          </ItemBox>
        )}
      </FlexBox>
    </Container>
  );
}

export default Features;
