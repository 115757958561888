var numeral = require('numeral');

export function getRandomArbitrary(min, max) {
    return (Math.random() * (max - min) + min);
}

export function getDate(lookback) {
    let now = new Date();
    const backdate = new Date(now.setDate(now.getDate() - lookback));
    const month = backdate.toLocaleString('default', { month: 'short' });
    return month + ". " + backdate.getUTCDate();
}

export const xTickFormat = (input) => {
    return numeral(input).format('$0.[00]a').toUpperCase();
};

export const yTickFormat = (input) => {
    return numeral(input).format('0.[00]a').toUpperCase();
};
