import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 3rem 0rem;
  margin: auto;
  margin-bottom: var(--section-bm);
  z-index: 999;
`;

export const ColoredContainer = styled.div`
  position: absolute;
  margin-left: calc(50% - 50vw);
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, #001964 0%, #04278E 100%);
`;

export const HeadingTitle = styled.h1`
  text-align: center;
  color: var(--text-primary-white);
  font-size: calc(1.35 * var(--h1-font-size));
  margin-bottom: 0.5rem;
  line-height: var(--header-line-height);
  font-weight: var(--header-font-weight);
  z-index: 999;
`;

export const Text = styled.p`
  text-align: center;
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  margin-bottom: 1.5rem;
  z-index: 999;
`;
