export const xAxis = [500, 1000, 1500, 2000, 2500, 3000];
export const yAxis = [20000000, 40000000, 60000000, 80000000];
export const splitMax = 70000000 / 3;

export const onLoadData = [
    {
        "price": 90.9090909090909,
        "Compound": 7215599.404695169,
        "Aave": 19599348.782364123,
        "Maker": 4469538.381016897
    },
    {
        "price": 181.8181818181818,
        "Compound": 8205992.039698923,
        "Aave": 3381111.278361096,
        "Maker": 19521067.7892303
    },
    {
        "price": 272.72727272727275,
        "Compound": 7107427.206564733,
        "Aave": 20892747.032993764,
        "Maker": 18817985.776473813
    },
    {
        "price": 363.6363636363636,
        "Compound": 14588405.61227648,
        "Aave": 21334905.076253112,
        "Maker": 14419282.596034233
    },
    {
        "price": 454.5454545454545,
        "Compound": 18206713.167251334,
        "Aave": 21061512.716343604,
        "Maker": 16270272.732146552
    },
    {
        "price": 545.4545454545455,
        "Compound": 22791503.191854928,
        "Aave": 19769167.879727118,
        "Maker": 5927412.005790411
    },
    {
        "price": 636.3636363636364,
        "Compound": 11902823.34937664,
        "Aave": 6762066.58873824,
        "Maker": 3702771.3183595375
    },
    {
        "price": 727.2727272727273,
        "Compound": 14028030.185250469,
        "Aave": 20776690.89523052,
        "Maker": 3426753.3117763745
    },
    {
        "price": 818.1818181818181,
        "Compound": 7731236.621534516,
        "Aave": 6966417.897487563,
        "Maker": 10709436.337694325
    },
    {
        "price": 909.090909090909,
        "Compound": 14028043.111812092,
        "Aave": 9575203.916734096,
        "Maker": 17207084.132398218
    },
    {
        "price": 1000,
        "Compound": 4566136.733665424,
        "Aave": 13073758.301983846,
        "Maker": 9973544.950459132
    },
    {
        "price": 1090.909090909091,
        "Compound": 18446542.46459385,
        "Aave": 12265843.518209701,
        "Maker": 4838502.142079596
    },
    {
        "price": 1181.8181818181818,
        "Compound": 20058095.475571238,
        "Aave": 4026504.513039873,
        "Maker": 20789044.12237065
    },
    {
        "price": 1272.7272727272727,
        "Compound": 14467641.260473747,
        "Aave": 22761350.70505145,
        "Maker": 17232216.9790252
    },
    {
        "price": 1363.6363636363635,
        "Compound": 7332519.518460011,
        "Aave": 19040959.947867554,
        "Maker": 2812471.519840079
    },
    {
        "price": 1454.5454545454545,
        "Compound": 9996533.979546541,
        "Aave": 1769128.0954145263,
        "Maker": 21080424.069845676
    },
    {
        "price": 1545.4545454545455,
        "Compound": 17305856.952376287,
        "Aave": 11971382.28186494,
        "Maker": 2445617.117232175
    },
    {
        "price": 1636.3636363636363,
        "Compound": 16922147.226196297,
        "Aave": 3551022.867936475,
        "Maker": 17616503.466413323
    },
    {
        "price": 1727.2727272727273,
        "Compound": 20749317.84931181,
        "Aave": 7881932.333381993,
        "Maker": 14114564.770721873
    },
    {
        "price": 1818.181818181818,
        "Compound": 2682693.297322478,
        "Aave": 10983648.688894356,
        "Maker": 4665795.316480907
    },
    {
        "price": 1909.090909090909,
        "Compound": 1961972.9424651554,
        "Aave": 9627749.587146578,
        "Maker": 18948327.26934626
    },
    {
        "price": 2000,
        "Compound": 15933133.456665983,
        "Aave": 13611704.383256458,
        "Maker": 15512812.13090581
    },
    {
        "price": 2090.909090909091,
        "Compound": 15915071.56345872,
        "Aave": 21002135.35645461,
        "Maker": 8471683.878552807
    },
    {
        "price": 2181.818181818182,
        "Compound": 840928.5197378979,
        "Aave": 6009536.405750199,
        "Maker": 421283.27920351183
    },
    {
        "price": 2272.7272727272725,
        "Compound": 11110062.462710058,
        "Aave": 4430133.950513614,
        "Maker": 14602200.34085553
    },
    {
        "price": 2363.6363636363635,
        "Compound": 16611898.765839286,
        "Aave": 12343283.08404243,
        "Maker": 20335358.98272542
    },
    {
        "price": 2454.5454545454545,
        "Compound": 15163472.870928017,
        "Aave": 4050900.2725767116,
        "Maker": 1392637.351450947
    },
    {
        "price": 2545.4545454545455,
        "Compound": 4367829.303634657,
        "Aave": 4353629.518367683,
        "Maker": 522686.7010392396
    },
    {
        "price": 2636.3636363636365,
        "Compound": 10429094.794742685,
        "Aave": 4330651.925067925,
        "Maker": 17145711.777633388
    },
    {
        "price": 2727.272727272727,
        "Compound": 5058814.503830598,
        "Aave": 2333633.1021210155,
        "Maker": 10104543.277106198
    },
    {
        "price": 2818.181818181818,
        "Compound": 581670.6395420297,
        "Aave": 5222553.110373936,
        "Maker": 20787922.539343357
    },
    {
        "price": 2909.090909090909,
        "Compound": 18411386.23719908,
        "Aave": 20278189.842143323,
        "Maker": 1235122.5662190807
    },
    {
        "price": 3000,
        "Compound": 18998184.6405815,
        "Aave": 13945783.33048086,
        "Maker": 16851720.65297005
    },
    {
        "price": 3090.909090909091,
        "Compound": 11557833.990407886,
        "Aave": 4713250.2856987445,
        "Maker": 18667868.592469625
    },
    {
        "price": 3181.8181818181815,
        "Compound": 5135770.022111078,
        "Aave": 5978671.2928593885,
        "Maker": 1006410.5744420374
    },
    {
        "price": 3272.7272727272725,
        "Compound": 1137493.4271552672,
        "Aave": 2158847.4358783467,
        "Maker": 8763845.121587219
    }
];
