import React from 'react';
import { Button, Link } from '@chakra-ui/react';
import { Container, ColoredContainer, HeadingTitle, Text } from './styles';

const Details = () => {
  return (
    <Container>
      <ColoredContainer />
      <HeadingTitle>Get in touch</HeadingTitle>
      <Text>Reach out and let us know how we can help.</Text>
      <Button
        as={Link}
        px="var(--button-px)"
        py="var(--button-py)"
        borderRadius="5px"
        fontSize="var(--p-font-size)"
        textColor="var(--text-primary-white)"
        boxShadow="0px 0px 64px rgba(58, 0, 0, 0.29)"
        background="var(--components-primary-blue)"
        _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        height="2rem"
        width="8rem"
        margin="auto"
        href="https://airtable.com/shrE48BMLWU3voxtB"
        target="_new"
      >
        Contact Team
      </Button>
    </Container>
    );
}

export default Details;