import React from 'react';
import { Stack, Button, Link } from '@chakra-ui/react';

const DesktopMenu = () => {
  return (
    <Stack spacing="1rem" direction="row" alignItems="center">
      <div />
      <Button
        className="header-app-button"
        as={Link}
        px="var(--button-px)"
        py="var(--button-py)"
        borderRadius="5px"
        fontSize="var(--p2-font-size)"
        textColor="var(--text-primary-white)"
        background="var(--components-primary-blue)"
        _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        width="135px"
        href="https://airtable.com/shrE48BMLWU3voxtB"
        target="_new"
      >
        Contact Team
      </Button>
    </Stack>
  )

}

export default DesktopMenu;
